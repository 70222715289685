import { CommonModule } from '@angular/common';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    inject,
    Input,
    signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import {
    GraphApiService,
    SharePointFile,
    SharePointSite,
    FileSource,
} from '@quipex/shared/data';
import { FileSizePipe } from '@quipex/shared/pipes';

interface FileWithSelection extends SharePointFile {
    selected: boolean;
    expanded?: boolean;
    children?: FileWithSelection[];
    level?: number;
    loading?: boolean;
}

// Add new interfaces for the metadata request
export interface ISharePointFileMetadata {
    downloadUrl: string;
    fileName: string;
}

export interface ImportSharePointFilesRequest {
    buildingId: string;
    files: ISharePointFileMetadata[];
}

@Component({
    selector: 'qpx-sharepoint-browser',
    templateUrl: './sharepoint-browser.component.html',
    styleUrls: ['./sharepoint-browser.component.scss'],
    imports: [
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatListModule,
        MatSelectModule,
        MatCheckboxModule,
        FormsModule,
        CommonModule,
        FileSizePipe,
    ],
})
export class SharePointBrowserComponent implements OnInit {
    @Input() buildingId!: string;
    @Input() userId!: number;
    @Output() fileSelected = new EventEmitter<ISharePointFileMetadata[]>();

    private readonly graphService = inject(GraphApiService);

    files: FileWithSelection[] = [];
    sites: SharePointSite[] = [];
    currentPath: string[] = [];
    isLoading = signal(false);
    isSiteLoading = signal(false);
    error = signal<string | null>(null);
    selectAll = signal(false);

    selectedSite: SharePointSite | null = null;

    selectedFilesCount = signal(0);
    isProcessing = false;

    async ngOnInit() {
        this.loadSites();
        this.loadRootItems();
    }

    private async loadSites() {
        try {
            this.isSiteLoading.set(true);
            this.sites = await this.graphService.getSharePointSites();
            if (this.sites.length > 0) {
                this.selectedSite = this.sites[0];
                await this.loadRootItems();
            }
        } catch (err) {
            this.error.set('Failed to load SharePoint sites.');
        } finally {
            this.isSiteLoading.set(false);
        }
    }

    async loadRootItems() {
        try {
            this.isLoading.set(true);
            this.error.set(null);

            if (this.selectedSite) {
                const items = await this.graphService.getRootItems(
                    FileSource.SharePoint,
                    this.selectedSite.id
                );
                this.files = items.map((item) => ({
                    ...item,
                    selected: false,
                    level: 0,
                }));
            } else {
                this.files = [];
            }
            this.currentPath = [];
            this.selectAll.set(false);
        } catch (err) {
            this.error.set(
                'Failed to load files. Please ensure you have the correct permissions.'
            );
            console.error('Error loading root items:', err);
        } finally {
            this.isLoading.set(false);
        }
    }

    async openFolder(folder: FileWithSelection) {
        if (!folder.folder) return;

        try {
            this.isLoading.set(true);
            this.error.set(null);
            const items = await this.graphService.getFolderItems(
                folder.id,
                FileSource.SharePoint,
                this.selectedSite?.id
            );
            this.files = items.map((item) => ({ ...item, selected: false }));
            this.currentPath.push(folder.name);
            this.selectAll.set(false);
        } catch (err) {
            this.error.set('Failed to open folder. Please try again.');
        } finally {
            this.isLoading.set(false);
        }
    }

    async onSiteChange() {
        this.isSiteLoading.set(true);
        try {
            await this.loadRootItems();
        } finally {
            this.isSiteLoading.set(false);
        }
    }

    async selectFile(file: FileWithSelection) {
        if (!file.folder) {
            try {
                this.isLoading.set(true);
                this.error.set(null);

                if (!this.selectedSite) {
                    throw new Error('No SharePoint site selected');
                }

                const fileMetadata: ISharePointFileMetadata = {
                    downloadUrl: file['@microsoft.graph.downloadUrl'],
                    fileName: file.name,
                };

                this.fileSelected.emit([fileMetadata]);
            } catch (err) {
                this.error.set('Failed to process file. Please try again.');
            } finally {
                this.isLoading.set(false);
            }
        }
    }

    // Method to get all selected files recursively
    private async getAllSelectedFiles(
        files: FileWithSelection[],
        currentPath: string[] = []
    ): Promise<ISharePointFileMetadata[]> {
        const selectedFiles: ISharePointFileMetadata[] = [];

        for (const file of files) {
            // If it's a selected folder, load its contents if not already loaded
            if (file.selected && file.folder && !file.children) {
                try {
                    file.loading = true;
                    const items = await this.graphService.getFolderItems(
                        file.id,
                        FileSource.SharePoint,
                        this.selectedSite?.id
                    );
                    file.children = items.map((item) => ({
                        ...item,
                        selected: true,
                        level: (file.level || 0) + 1,
                    }));
                    file.expanded = true;
                } catch (err) {
                    console.error('Error loading folder contents:', err);
                } finally {
                    file.loading = false;
                }
            }

            // If it's a selected file (not a folder), add it to the results
            if (file.selected && !file.folder) {
                selectedFiles.push({
                    downloadUrl: file['@microsoft.graph.downloadUrl'],
                    fileName: file.name,
                });
            }

            // If it has children (is a folder), recursively process them
            if (file.folder && file.children?.length) {
                const nestedFiles = await this.getAllSelectedFiles(
                    file.children,
                    [...currentPath, file.name]
                );
                selectedFiles.push(...nestedFiles);
            }
        }

        return selectedFiles;
    }

    // Update the toggleSelectAll method to handle nested files
    toggleSelectAll() {
        const newValue = !this.selectAll();
        this.selectAll.set(newValue);

        const updateFilesRecursively = (files: FileWithSelection[]) => {
            files.forEach((file) => {
                file.selected = newValue;
                if (file.children) {
                    updateFilesRecursively(file.children);
                }
            });
        };
        updateFilesRecursively(this.files);
        this.updateSelectedCount();
    }

    // Update toggleFileSelection to handle nested files and emit all selected files
    async toggleFileSelection(file: FileWithSelection) {
        file.selected = !file.selected;
        // If it's a folder, load its contents if not already loaded and toggle all children
        if (file.folder) {
            // Load folder contents if not already loaded
            if (!file.children && file.selected) {
                try {
                    file.loading = true;
                    const items = await this.graphService.getFolderItems(
                        file.id,
                        FileSource.SharePoint,
                        this.selectedSite?.id
                    );
                    file.children = items.map((item) => ({
                        ...item,
                        selected: file.selected,
                        level: (file.level || 0) + 1,
                    }));
                    // file.expanded = true;
                } catch (err) {
                    console.error('Error loading folder contents:', err);
                } finally {
                    file.loading = false;
                }
            }

            // Toggle all children
            if (file.children) {
                const updateChildren = (children: FileWithSelection[]) => {
                    children.forEach((child) => {
                        child.selected = file.selected;
                        if (child.children) {
                            updateChildren(child.children);
                        }
                    });
                };
                updateChildren(file.children);
            }
        }

        this.updateSelectedCount();
        this.updateParentSelectAllState();

        // Emit all currently selected files (including from folders)
        const selectedFiles = await this.getAllSelectedFiles(this.files);
        this.selectedFilesCount.set(selectedFiles.length);
        this.fileSelected.emit(selectedFiles);
    }

    // Update updateSelectedCount to be more accurate
    private updateSelectedCount() {
        const countSelectedFiles = (files: FileWithSelection[]): number => {
            let count = 0;
            for (const file of files) {
                if (!file.folder && file.selected) {
                    count++;
                }
                if (file.folder && file.children?.length) {
                    count += countSelectedFiles(file.children);
                }
            }
            return count;
        };

        const count = countSelectedFiles(this.files);
        this.selectedFilesCount.set(count);
    }

    // Update select all checkbox state based on selections
    private updateParentSelectAllState() {
        const areAllSelected = (files: FileWithSelection[]): boolean => {
            return files.every((file) => {
                if (file.children) {
                    return areAllSelected(file.children);
                }
                return file.folder || file.selected;
            });
        };

        this.selectAll.set(areAllSelected(this.files));
    }

    getFileIcon(file: FileWithSelection): string {
        if (file.folder) {
            return 'folder';
        }

        const extension = file.name.split('.').pop()?.toLowerCase();
        switch (extension) {
            case 'pdf':
                return 'picture_as_pdf';
            case 'doc':
            case 'docx':
                return 'description';
            case 'xls':
            case 'xlsx':
                return 'table_chart';
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return 'image';
            default:
                return 'insert_drive_file';
        }
    }

    // Update the existing toggleFolder method to initialize children's selected state
    async toggleFolder(folder: FileWithSelection, event: Event) {
        event.stopPropagation();
        if (!folder.folder) return;

        if (!folder.expanded) {
            try {
                folder.loading = true;
                const items = await this.graphService.getFolderItems(
                    folder.id,
                    FileSource.SharePoint,
                    this.selectedSite?.id
                );
                folder.children = items.map((item) => ({
                    ...item,
                    selected: folder.selected, // Inherit parent's selected state
                    level: (folder.level || 0) + 1,
                }));
                folder.expanded = true;
                this.updateSelectedCount();
            } catch (err) {
                this.error.set(
                    'Failed to load folder contents. Please try again.'
                );
                console.error('Error loading folder:', err);
            } finally {
                folder.loading = false;
            }
        } else {
            folder.expanded = false;
        }
    }
}

