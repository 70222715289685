@if (!isLoaded()) {
    <qpx-loader />
}

@switch (fileType()) {
    @case ('pdf') {
        <ngx-extended-pdf-viewer
            (pdfLoaded)="onContentLoaded()"
            [src]="docUrl()"
            [height]="previewHeight()"
            [showHandToolButton]="true"
            [showToolbar]="true"
            [showSidebarButton]="true"
            [showFindButton]="true"
            [showPagingButtons]="false"
            [showDrawEditor]="false"
            [showStampEditor]="false"
            [showTextEditor]="false"
            [showZoomButtons]="false"
            [showPresentationModeButton]="false"
            [showOpenFileButton]="false"
            [showPrintButton]="false"
            [showDownloadButton]="true"
            [showSecondaryToolbarButton]="false"
            [showRotateButton]="false"
            [showScrollingButtons]="false"
            [showSpreadButton]="false"
            [showPropertiesButton]="false"
            [textLayer]="true"
        />
    }

    @case ('image') {
        <div class="image-wrapper" [style.height]="previewHeight() ?? null">
            <img
                [src]="docUrl()"
                alt="document preview"
                (load)="onContentLoaded(true)"
            />
        </div>
    }

    @default {
        <!--Document Type e.g docx, doc, ppt, xlsx-->
        <ngx-doc-viewer
            [googleCheckInterval]="2000"
            [googleCheckContentLoaded]="true"
            [googleMaxChecks]="5"
            (loaded)="onContentLoaded()"
            [url]="docUrl()"
            viewer="google"
            [style.height]="previewHeight() ?? null"
        />
    }
}
