import { pipe } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';

export const DEFAULT_MINIMUM_SEARCH_CHARACTERS = 3;

export interface ValidateSearchPipeConfig {
    /** in milliseconds */
    debounce?: number;
}

/** debounce and trim the search input */
export const validateSearchInput = (config: ValidateSearchPipeConfig = {}) => {
    const { debounce = 350 } = config;

    return pipe(
        debounceTime(debounce),
        distinctUntilChanged(),
        filter((value: unknown): value is string => typeof value === 'string'),
        map((value) => value.trim())
    );
};
