import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    private readonly dataSubject = new BehaviorSubject<any>(null);
    public readonly data$ = this.dataSubject.asObservable();

    private readonly queryParamsSubject = new BehaviorSubject<any>(null);
    public readonly queryParams$ = this.queryParamsSubject.asObservable();

    private submissionSubject = new Subject<void>();
    public submission$: Observable<void> =
        this.submissionSubject.asObservable();

    private readonly toggleSubmitButtonSubject = new BehaviorSubject<boolean>(
        true
    );
    public readonly toggleSubmitButton$ =
        this.toggleSubmitButtonSubject.asObservable();

    public updateData(data: any) {
        this.dataSubject.next(data);
        this.toggleIsSubmitDisabled(false);
    }

    public updateQueryParams(queryParams: any) {
        this.queryParamsSubject.next(queryParams);
    }

    // Method to trigger a submission event (i.e from the modal)
    public submit(): void {
        this.submissionSubject.next();
    }

    public resetModalState(): void {
        this.dataSubject.next(null);
        this.submissionSubject = new Subject<void>(); // Reset to empty state
        this.submission$ = this.submissionSubject.asObservable(); // Update observable
        this.toggleIsSubmitDisabled(true);
    }

    private toggleIsSubmitDisabled(isDisabled: boolean): void {
        this.toggleSubmitButtonSubject.next(isDisabled);
    }
}
