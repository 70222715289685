import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    OnInit,
    inject,
    model,
} from '@angular/core';
import { DialogService } from '@quipex/shared/data';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IDocumentPreviewDialogData {
    docGuid: string;
    searchTerm: string;
    dialogTitle: string;
    standaloneDialog: boolean;
}

@Component({
    selector: 'qpx-document-preview-modal',
    templateUrl: './document-preview-modal.component.html',
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        DocumentPreviewComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewModalComponent implements OnInit {
    protected readonly data: IDocumentPreviewDialogData =
        inject(MAT_DIALOG_DATA);
    private readonly dialogService = inject(DialogService);
    private readonly dialogRef =
        inject<MatDialogRef<DocumentPreviewModalComponent>>(MatDialogRef);
    private readonly destroyRef = inject(DestroyRef);

    /**
     * if component is created by another dialog,
     * then pass data in via `IDocumentPreviewDialogData`
     * and call it directly via 'this.matDialog.open(DocumentPreviewModalComponent,..'
     */
    public readonly docGuid = model.required<string>();

    /**
     * if component is created by another dialog,
     * then pass data in via `IDocumentPreviewDialogData`
     * and call it directly via 'this.matDialog.open(DocumentPreviewModalComponent,..'
     */
    public readonly searchTerm = model('');

    ngOnInit(): void {
        if (this.data?.standaloneDialog) {
            this.docGuid.set(this.data.docGuid);
            this.searchTerm.set(this.data.searchTerm);
        }

        this.dialogService.requestCancel$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.dialogService.closeDialog(false));
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
