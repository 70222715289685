import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { MonitoringService } from './monitoring.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private readonly monitoringService = inject(MonitoringService);
    private readonly zone = inject(NgZone);

    handleError(error: Error): void {
        // Run inside NgZone to ensure UI updates
        this.zone.run(() => {
            // Log the error
            this.monitoringService.logException(error, SeverityLevel.Warning);

            // Log additional context
            this.monitoringService.logEvent('UnhandledError', {
                errorName: error.name,
                errorMessage: error.message,
                stackTrace: error.stack,
                url: window.location.href,
                timestamp: new Date().toISOString(),
            });
        });
    }
}
