import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatRowDef, MatTable } from '@angular/material/table';

/**
 * type-safe alternative to *matRowDef
 * @reference https://nartc.me/blog/typed-mat-cell-def/
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[matRowDef]', // same selector as matRowDef
    providers: [{ provide: CdkCellDef, useExisting: TypeSafeMatRowDef }],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TypeSafeMatRowDef<T> extends MatRowDef<T> {
    // leveraging syntactic-sugar syntax when we use *matCellDef

    @Input() matRowDefTable?: MatTable<T>;

    // ngTemplateContextGuard flag to help with the Language Service
    static ngTemplateContextGuard<T>(
        dir: TypeSafeMatRowDef<T>,
        ctx: unknown
    ): ctx is { $implicit: T; index: number } {
        return true;
    }
}
