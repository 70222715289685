import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Observable, from, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GoogleMapsLoaderService {
    private _loadedMap$: Observable<google.maps.MapsLibrary | null> = of(null);
    private _loadedMarker$: Observable<google.maps.MarkerLibrary | null> =
        of(null);

    private readonly _loader = new Loader({
        apiKey: 'AIzaSyDUFgMZV4DdS3_9rKhTVB43bkZlK06m9Z0',
        version: 'weekly',
        libraries: ['places'],
    });

    public readonly loadedMap$ = this._loadedMap$.pipe(
        switchMap((value) => {
            if (!value) {
                this._loadedMap$ = this.loadMap();
            }

            return this._loadedMap$ as Observable<google.maps.MapsLibrary>;
        })
    );

    public readonly loadedMarker$ = this._loadedMarker$.pipe(
        switchMap((value) => {
            if (!value) {
                this._loadedMarker$ = this.loadMarker();
            }

            return this._loadedMarker$ as Observable<google.maps.MarkerLibrary>;
        })
    );

    private loadMap(): Observable<google.maps.MapsLibrary> {
        return from(this._loader.importLibrary('maps'));
    }

    private loadMarker(): Observable<google.maps.MarkerLibrary> {
        return from(this._loader.importLibrary('marker'));
    }
}
