import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    inject,
    input,
    OnInit,
    signal,
} from '@angular/core';
import {
    DocumentApiService,
    DownloadDocumentResponse,
} from '@quipex/shared/data';
import { FileHelper } from '@quipex/shared/helpers';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { environment } from 'src/environments/environment';
import {
    NgxExtendedPdfViewerModule,
    NgxExtendedPdfViewerService,
} from 'ngx-extended-pdf-viewer';
import { LoaderComponent } from '../../loader.component';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, filter, switchMap } from 'rxjs';

const FILE_PREVIEW_TYPES = {
    Pdf: 'pdf',
    Image: 'image',
    Doc: 'doc',
} as const;

type FilePreviewType =
    (typeof FILE_PREVIEW_TYPES)[keyof typeof FILE_PREVIEW_TYPES];

@Component({
    selector: 'qpx-document-preview',
    imports: [LoaderComponent, NgxDocViewerModule, NgxExtendedPdfViewerModule],
    templateUrl: './document-preview.component.html',
    styleUrl: './document-preview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewComponent implements OnInit {
    private readonly documentService = inject(DocumentApiService);
    private readonly ngxExtendedPdfViewerService = inject(
        NgxExtendedPdfViewerService
    );
    private readonly destroyRef = inject(DestroyRef);
    private readonly cdr = inject(ChangeDetectorRef);

    protected readonly filePreviewTypes = FILE_PREVIEW_TYPES;

    public readonly docGuid = input.required<string>();
    private readonly docGuid$ = toObservable(this.docGuid);
    public readonly searchTerm = input('');
    public readonly previewHeight = input<string | undefined>('80vh');

    protected readonly isLoaded = signal(false);
    protected readonly docUrl = signal('');
    protected readonly fileType = signal<FilePreviewType>(
        FILE_PREVIEW_TYPES.Doc
    );

    ngOnInit(): void {
        this.getFileDownloadUrl();
    }

    protected onContentLoaded(isImage = false): void {
        this.isLoaded.set(true);

        if (!isImage && this.searchTerm()) {
            this.setPdfViewerElements(this.searchTerm());
        }
    }

    private setPdfViewerElements(searchTerm: string): void {
        // search via input params
        this.ngxExtendedPdfViewerService.find(searchTerm);

        // we must maniuplate the dom directly here as we don't have access to these elements (3rd party lib)
        const findSearchBar = document.getElementById('primaryViewFind');
        if (findSearchBar != null) {
            findSearchBar.click();
        }

        const highlightAllCheckbox = document.getElementById(
            'findHighlightAll'
        ) as HTMLInputElement;
        if (highlightAllCheckbox) {
            // Enable the checkbox
            highlightAllCheckbox.checked = true;
        }

        // since manipulating dom directly we need to trigger change detection
        this.cdr.markForCheck();
    }

    private getFileDownloadUrl(): void {
        this.docGuid$
            .pipe(
                filter((id) => !!id),
                distinctUntilChanged(),
                switchMap((docGuid) => {
                    this.isLoaded.set(false);
                    this.fileType.set(FILE_PREVIEW_TYPES.Doc);

                    return this.documentService.getDocumentUrlsToDownload([
                        docGuid,
                    ]);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (response: DownloadDocumentResponse) => {
                    if (!response.documents.length) return;

                    const { fileName, downloadUrl } = response.documents[0];

                    this.docUrl.set(downloadUrl);
                    this.checkFileType(fileName);
                },
                error: (err) => {
                    console.error('Error fetching document URL:', err);
                },
            });
    }

    private checkFileType(fileName: string): void {
        const rawfileType = FileHelper.extractFileExtension(fileName);
        let sanitisedFileType: FilePreviewType;

        if (!rawfileType) {
            sanitisedFileType = FILE_PREVIEW_TYPES.Doc;
        } else if (environment.imagePreviewFileTypes.includes(rawfileType)) {
            sanitisedFileType = FILE_PREVIEW_TYPES.Image;
        } else if (rawfileType === 'pdf') {
            sanitisedFileType = FILE_PREVIEW_TYPES.Pdf;
        } else {
            sanitisedFileType = FILE_PREVIEW_TYPES.Doc;
        }

        this.fileType.set(sanitisedFileType);
    }
}
