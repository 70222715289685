import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'qpx-loader',
    template: `<mat-spinner
        [color]="color()"
        class="qpx-loader"
        [ngClass]="inputClasses()"
    ></mat-spinner>`,
    imports: [NgClass, MatProgressSpinnerModule],
    host: {
        '[class]': 'containerClass()',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    inputClasses = input<string[]>([]);
    color = input<'primary' | 'accent'>('primary');
    containerClass = input('load-center-absolute');
}
