<div class="sharepoint-browser">
    <div class="controls">
        <div class="site-selector">
            <div class="label-wrapper">
                <label>SharePoint site:</label>
            </div>
            <mat-select
                [(ngModel)]="selectedSite"
                (selectionChange)="onSiteChange()"
                class="site-select"
                [disabled]="!sites.length || isSiteLoading()"
                placeholder="Select a SharePoint site"
            >
                <mat-option *ngFor="let site of sites" [value]="site">
                    {{ site.displayName }}
                </mat-option>
            </mat-select>
        </div>
    </div>

    <div class="selected-files-count" *ngIf="selectedFilesCount() > 0">
        {{ selectedFilesCount() }} file{{
            selectedFilesCount() === 1 ? '' : 's'
        }}
        selected
    </div>

    <div class="content">
        <mat-spinner
            *ngIf="isLoading() || isSiteLoading()"
            [diameter]="40"
        ></mat-spinner>

        <div *ngIf="error()" class="error-message">
            {{ error() }}
        </div>

        <div *ngIf="!isLoading() && !error()" class="file-list">
            <div class="select-all">
                <mat-checkbox
                    [checked]="selectAll()"
                    (change)="toggleSelectAll()"
                    color="primary"
                >
                    Select all
                </mat-checkbox>
            </div>

            <mat-nav-list>
                <ng-container
                    *ngTemplateOutlet="
                        folderTemplate;
                        context: { $implicit: files, level: 0 }
                    "
                >
                </ng-container>
            </mat-nav-list>
        </div>
    </div>
</div>

<ng-template #folderTemplate let-items let-level="level">
    <ng-container *ngFor="let item of items">
        <mat-list-item
            [class.is-folder]="item.folder"
            [style.padding-left.px]="level * 24"
        >
            <mat-checkbox
                [checked]="item.selected"
                (change)="toggleFileSelection(item)"
                color="primary"
                (click)="$event.stopPropagation()"
                [disabled]="item.loading"
            >
            </mat-checkbox>
            <div class="file-item">
                <button
                    *ngIf="item.folder"
                    mat-icon-button
                    class="expand-button"
                    (click)="toggleFolder(item, $event)"
                    [disabled]="item.loading"
                >
                    <mat-icon
                        class="expand-icon"
                        [class.expanded]="item.expanded"
                        [class.rotating]="item.loading"
                    >
                        {{ item.loading ? 'sync' : 'chevron_right' }}
                    </mat-icon>
                </button>
                <mat-icon>{{ getFileIcon(item) }}</mat-icon>
                <span
                    class="file-name"
                    (click)="
                        item.folder
                            ? toggleFolder(item, $event)
                            : selectFile(item)
                    "
                >
                    {{ item.name }}
                </span>
                <span *ngIf="!item.folder" class="file-size">
                    {{ item.size | fileSize }}
                </span>
            </div>
        </mat-list-item>
        <ng-container *ngIf="item.expanded && item.children">
            <ng-container
                *ngTemplateOutlet="
                    folderTemplate;
                    context: { $implicit: item.children, level: level + 1 }
                "
            >
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

