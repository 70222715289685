import { Injectable } from '@angular/core';
import {
    ApplicationInsights,
    ICustomProperties,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable()
export class MonitoringService {
    private appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.instrumentationKey,
            },
        });
        this.appInsights.loadAppInsights();
    }

    logEvent(name: string, properties?: ICustomProperties) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: ICustomProperties) {
        this.appInsights.trackMetric(
            { name: name, average: average },
            properties
        );
    }

    logException(exception: Error, severityLevel?: SeverityLevel) {
        this.appInsights.trackException({
            exception: exception,
            severityLevel: severityLevel,
        });
    }

    logTrace(message: string, properties?: ICustomProperties) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
