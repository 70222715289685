import { NgTemplateOutlet } from '@angular/common';
import {
    Component,
    inject,
    input,
    computed,
    output,
    signal,
    TemplateRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ReviewBuildingDetailsComponent } from '../../review-building-details/review-building-details.component';
import {
    AzureFunctionApiService,
    BuildingDocuments,
    ClassifyDocumentResponse,
    DialogService,
    DocDto,
    DocumentApiService,
    DocumentTypeEnum,
    IOCDetailsExtraction,
    SharedService,
    UserStore,
} from '@quipex/shared/data';
import { FileHelper } from '@quipex/shared/helpers';
import { filter, firstValueFrom, mergeWith } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FileListComponent } from '../file-list/file-list.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { DocumentLoaderComponent } from '../upload-files/document-loading-screen/document-loading-screen';

@Component({
    selector: 'qpx-bulk-upload-files',
    templateUrl: './bulk-upload-files.component.html',
    styleUrls: ['./bulk-upload-files.component.scss'],
    imports: [
        NgTemplateOutlet,
        MatFormFieldModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        DocumentLoaderComponent,
        FileUploadComponent,
        FileListComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUploadFilesComponent {
    private readonly sharedService = inject(SharedService);
    private readonly documentService = inject(DocumentApiService);
    private readonly azFunctionService = inject(AzureFunctionApiService);
    private readonly userStore = inject(UserStore);
    private readonly snackBar = inject(MatSnackBar);
    private readonly dialog = inject(MatDialog);
    public readonly dialogService = inject(DialogService);

    public readonly _buildingId = input('', {
        // eslint-disable-next-line @angular-eslint/no-input-rename
        alias: 'buildingId',
    });
    private readonly _buildingId$ = toObservable(this._buildingId).pipe(
        mergeWith(
            this.sharedService.queryParams$.pipe(
                filter((params) => typeof params === 'string')
            )
        )
    );
    protected readonly buildingId = toSignal(this._buildingId$, {
        initialValue: '',
    });

    protected readonly files = signal<DocDto[]>([]);
    /** public-facing only, do not use internally */
    public readonly _files = input<DocDto[], DocDto[]>([], {
        transform: (value) => {
            this.files.set(value);
            return value;
        },
        // eslint-disable-next-line @angular-eslint/no-input-rename
        alias: 'files',
    });

    public readonly viewType = input<
        'upload-with-file-list' | 'upload-with-tags' | 'tags-only'
    >('upload-with-tags');
    public readonly shouldCheckForDuplicates = input(true);
    /** template for loading state */
    public readonly loadingTpl = input<TemplateRef<any> | null>(null);
    public readonly showBuildingReviewScreen = input(false);
    /** allow classification of documents */
    public readonly canClassifyDocuments = input(true);

    public readonly filesSelectionChange = output<DocDto[]>();
    public readonly uploadFinished = output<void>();
    public readonly isProcessingChange = output<boolean>();
    public readonly canConfirmChange = output<boolean>();
    public readonly sharePointSelected = output<void>();

    protected readonly isLoading = signal(false);

    private readonly me$ = this.userStore.select('me');
    private readonly me = toSignal(this.me$);
    private readonly _currentUserId = computed(
        () => this.me()?.internalId ?? 0
    );

    private readonly _processedFiles: Set<string> = new Set();

    protected onFileRemove(fileId: string): void {
        this.removeFile(fileId);
        this.filesSelectionChange.emit(this.files());
    }

    onSharePointClick(): void {
        this.sharePointSelected.emit();
        this.dialogService.requestConfirm$.next();
    }

    fileProcessingChange(processing: boolean): void {
        this.isLoading.set(!processing);
    }
    protected onFileUploadListChanged(files: File[]): void {
        if (files?.length) {
            this.prepareFilesList(files);
        } else {
            this.isLoading.set(false);
        }
    }

    protected onFileListChange(): void {
        // files object has been altered inside file-list component
        this.filesSelectionChange.emit(this.files());
    }

    public async handleUploadDocuments() {
        this.isProcessingChange.emit(true);
        const documents = this.documentService.prepareDocumentsForUpload(
            this.files(),
            this.buildingId(),
            this._currentUserId()
        );
        await this.uploadDocuments(documents);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    private prepareFilesList(files: File[]) {
        this.isLoading.set(true);
        this.canConfirmChange.emit(false);

        const newDocs: DocDto[] = Array.from(files).map((item) => ({
            id: uuidv4(),
            autoTag: [],
            isKeyDocument: false,
            file: item,
            documentType: null,
        }));
        this.files.update((f) => [...f, ...newDocs]);

        const newFiles = this.filterNewFiles(files);
        this.uploadAndClassifyDocuments(newFiles);
    }

    private uploadAndClassifyDocuments(fileList: File[]): void {
        this.isLoading.set(true);
        // Remove files that aren't supported for auto tagging
        const files = fileList.filter((file) =>
            FileHelper.isFileAutoTagType(file.name)
        );

        if (!this.canClassifyDocuments() || !files.length) {
            if (files.length) {
                this.filesSelectionChange.emit(this.files());
            }
            this.canConfirmChange.emit(true);
            this.isLoading.set(false);
        } else {
            this.canConfirmChange.emit(false);
            this.documentService.classifyDocuments(files).subscribe({
                next: (res: ClassifyDocumentResponse[]) => {
                    this.addDocumentTypeToFiles(res);
                    this.canConfirmChange.emit(true);
                    this.isLoading.set(false);
                },
                error: () => {
                    this.canConfirmChange.emit(true);
                    this.isLoading.set(false);
                },
            });
        }
    }

    private addDocumentTypeToFiles(response: ClassifyDocumentResponse[]) {
        this.files.update((files) => {
            const updatedFiles = files.map((file) => {
                const matchingDocument = response
                    .filter((x) => x.fileName == file.file.name)
                    .pop();

                if (matchingDocument) {
                    // set a new property autoTag to be the matching tag from the response
                    file.isKeyDocument = true;
                    file.documentType = matchingDocument.documentType;
                }
                return file;
            });

            return updatedFiles;
        });

        this.files().forEach((file) => {
            if (
                this.showBuildingReviewScreen() &&
                file.documentType === DocumentTypeEnum.OccupancyPermit
            ) {
                this.extractOCDetails(file);
            }
        });

        this.filesSelectionChange.emit(this.files());
        this.canConfirmChange.emit(true);
        this.isLoading.set(false);
    }

    private extractOCDetails(file: DocDto): void {
        this.documentService.extractOCDetails(file.file).subscribe((res) => {
            // Only show the modal if all the properties are not empty or null
            if (
                Object.values(res).some(
                    (value) =>
                        value !== '' &&
                        value !== null &&
                        value !== undefined &&
                        !(Array.isArray(value) && value.length === 0)
                )
            ) {
                this.openReviewBuildingDetailsModal(res);
            }
        });
    }

    private async uploadDocuments(documents: BuildingDocuments) {
        try {
            await firstValueFrom(
                this.documentService.uploadDocument(documents)
            );

            this.azFunctionService.runSearchIndexer().subscribe();
            this.createToast(
                'success',
                'File(s) has been uploaded successfully!'
            );
            this.uploadFinished.emit();
        } catch (err) {
            this.createToast('danger', 'File(s) File upload failed!');
            const error = err instanceof Error ? err : new Error(`${err}`);
            console.error(error);
        } finally {
            this.isProcessingChange.emit(false);
            this.files.set([]);
        }
    }

    private createToast(panelClass: string, title: string): void {
        const icon = panelClass === 'success' ? 'check_circle' : 'error';
        this.snackBar.open(title, icon, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: panelClass,
            duration: 1500,
        });
    }

    private removeFile(id: string): void {
        const index = this.files().findIndex((file) => file.id === id);
        if (index !== -1) {
            this.files.update((files) => {
                const filteredFiles = files.filter((_, idx) => idx !== index);
                return filteredFiles;
            });
        }
    }

    private filterNewFiles(files: File[]): File[] {
        return files.filter((file) => {
            const fileId = this.generateFileId(file);
            if (!this._processedFiles.has(fileId)) {
                this._processedFiles.add(fileId);
                return true;
            }
            return false;
        });
    }

    private openReviewBuildingDetailsModal(
        ocDetailsExtraction: IOCDetailsExtraction
    ): void {
        this.dialog.open(ReviewBuildingDetailsComponent, {
            disableClose: true,
            panelClass: 'qpx-dialog',
            data: {
                buildingId: this.buildingId,
                ocDetailsExtraction: ocDetailsExtraction,
            },
        });
    }

    private generateFileId(file: File): string {
        return `${uuidv4()}_${file.name}`;
    }
}
