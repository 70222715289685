import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    linkedSignal,
    signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import {
    NotificationApiService,
    NotificationModel,
    NotificationSection,
    TasksApiService,
    GetNotificationsRequestDTO,
    GetNotificationsResponseDTO,
} from '@quipex/shared/data';
import {
    catchError,
    distinctUntilChanged,
    EMPTY,
    filter,
    finalize,
    of,
    pairwise,
    startWith,
} from 'rxjs';
import { LoaderComponent } from '../loader.component';

@Component({
    selector: 'qpx-notifications',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
    imports: [
        DatePipe,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        LoaderComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '(document:click)': 'onClickAway($event)',
        '(keydown.escape)': 'onEscapePress()',
    },
})
export class NotificationComponent {
    private readonly destroyRef = inject(DestroyRef);
    private readonly notificationService = inject(NotificationApiService);
    private readonly router = inject(Router);
    private readonly elRef = inject(ElementRef, { host: true });
    private readonly tasksService = inject(TasksApiService);

    protected readonly unreadCount = signal(0);
    protected readonly notifications = signal<NotificationModel[]>([]);
    protected readonly isDropdownOpen = signal(false);
    private readonly isDropdownOpen$ = toObservable(this.isDropdownOpen);
    protected readonly isLoading = signal(true);

    private readonly notificationsRequest =
        linkedSignal<GetNotificationsRequestDTO>(() => ({
            pageNo: 1,
            pageSize: 10,
        }));

    constructor() {
        // hacky way to update count on navigation
        this.onNavigationChanges().subscribe(() => {
            this.fetchNotificaionCount();
        });

        // mark all as read on dropdown close events
        this.onDropdownCloseEvents().subscribe(() => {
            this.markAllAsRead();
        });
    }

    private onNavigationChanges() {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationStart),
            startWith(EMPTY), // to trigger on init
            takeUntilDestroyed()
        );
    }

    /** excludes initial value */
    private onDropdownCloseEvents() {
        return this.isDropdownOpen$.pipe(
            distinctUntilChanged(),
            pairwise(),
            filter(([prev, next]) => prev != null && !next),
            takeUntilDestroyed()
        );
    }

    protected toggleNotifications() {
        this.isDropdownOpen.update((state) => !state);

        this.fetchNotifications();
    }

    private fetchNotificaionCount() {
        this.notificationService
            .getNotificationsCount()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
                this.unreadCount.set(data);
            });
    }

    private markAllAsRead() {
        return this.notificationService
            .readAll()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.unreadCount.set(0);
            });
    }

    protected navigateToDetails(notification: NotificationModel): void {
        let routeExtras = '';
        let url = '';

        const listSectionsNavToTask = [
            NotificationSection.OrgAssignedTask,
            NotificationSection.UserAssignedTask,
            NotificationSection.NewCriticalTask,
            NotificationSection.Review,
        ];

        const isDocumentProcessing =
            notification.section === NotificationSection.DocumentProcessing;

        if (listSectionsNavToTask.includes(notification.section)) {
            url = `/tasks`;
            this.tasksService.setBuildingId(notification.buildingId);
        } else if (isDocumentProcessing) {
            url = `/buildings/${notification.buildingIdentifier}`;
        } else {
            url = `/buildings/${notification.buildingIdentifier}/maintenance`;

            switch (notification.section) {
                case NotificationSection.ESMUpcominng:
                    routeExtras = 'esm';
                    break;
                case NotificationSection.ESMOverdue:
                    routeExtras = 'esm';
                    break;
                case NotificationSection.ABSSDue:
                    routeExtras = 'abss';
                    break;
                case NotificationSection.ABSSOverdue:
                    routeExtras = 'abss';
                    break;
            }
        }

        this.isDropdownOpen.set(false);
        this.router.navigate([url], {
            state: {
                tab: routeExtras,
                buildingId: notification.buildingId,
                ...(isDocumentProcessing ? { action: 'task' } : {}),
            },
        });
    }

    protected markAsRead(id: number): void {
        this.notificationService
            .delete(id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.fetchNotifications();
                this.unreadCount.update((count) => (count > 0 ? count - 1 : 0));
            });
    }

    protected isOverdue(description?: string): boolean {
        if (!description) return false;

        return description.includes('overdue');
    }

    private fetchNotifications() {
        this.isLoading.set(true);

        this.notificationService
            .getNotifications(this.notificationsRequest())
            .pipe(
                catchError(() => {
                    const emptyResponse: GetNotificationsResponseDTO = {
                        totalCount: 0,
                        notifications: [],
                    };
                    return of(emptyResponse);
                }),
                finalize(() => this.isLoading.set(false)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((data) => {
                this.notifications.set(data.notifications ?? []);
            });
    }

    /** Close the dropdown when clicking outside */
    protected onClickAway(event: Event) {
        if (!this.isDropdownOpen()) return;

        if (!this.elRef.nativeElement.contains(event.target)) {
            this.isDropdownOpen.set(false);
        }
    }

    protected onEscapePress(): void {
        this.isDropdownOpen.set(false);
    }
}
