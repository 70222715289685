import {
    DocumentCategoryEnum,
    DocumentTypeEnum,
    KeyDocumentTypes,
    SortOrder,
} from './enums.model';

export enum DocumentSortFieldEnum {
    None = -1,
    DocumentName = 20,
    ModificationDate = 30,
}

export class AddDocumentComment {
    comment: string | undefined;
}

export class CommentsResponse {
    comments: Array<DocumentComments> = [];
}

export class DocumentComments {
    comment: string | undefined;
    commentedBy: string | undefined;
    commentGuid: string | undefined;
}

export class Tag {
    tagName!: string;
    tagGuid?: string;
    organizationId?: string;
}

export class AddTags {
    tags: Array<Tag> = [];
    documentGuid!: string;
    userId!: number;
}

export class DocumentDetails {
    documentGuid: string | undefined;
    name: string | undefined;
    fileName!: string;
    fileCategory: any;
    documentType!: DocumentTypeEnum; // todo: check this property on itegration
    previewUrl = '';
    status?: string;
    userId!: number;
    buildingId!: string;
    tags?: Tag[];
}
export class ArchiveDeleteDocModel {
    requirementid!: string;
    documentguids: Array<any> = [];
    evidenceids: Array<any> = [];
    archived = false;
}
export class areaModel {
    id!: number;
    name!: string;
}

//TODO should be deleted
export class AddBuildingDocument {
    buildingId!: string;
    fileBase64String!: string;
    fileExtension!: string;
    fileName!: string;
    fileSizeInBytes!: number;
    tags?: Array<Tag> = [];
    userId!: number;
}

export class BuildingDocument {
    uploadedFile!: File;
    documentType!: DocumentTypeEnum;
    tags?: Array<Tag> = [];
}

export class BuildingDocuments {
    buildingId!: string;
    buildingDocuments: Array<BuildingDocument> = [];
    userId!: number;
}

export interface Doc {
    documentGuid: string;
    documentName: string;
    documentCategory: string;
    documentType: string;
    fileSizeInBytes: number;
    lastModifiedBy: string;
    uploadedAt: Date;
    tags: Array<Tag>;
    units: IDocUnit[];
}

export interface IDocUnit {
    id: number;
    unitNumber: string;
}

export interface DocDto {
    id: string;
    autoTag: Array<Tag>;
    isKeyDocument?: boolean;
    documentType: DocumentTypeEnum | null;
    file: File;
}

export interface Tag {
    tagName: string;
    keyDocumentType: number | null | undefined;
    tagGuid?: string;
    tagKeyDocType?: KeyDocumentTypes;
}

export interface FileDto {
    id: string;
    pdfFiles: File;
}

export interface ClassifyDocumentResponse {
    fileName: string;
    documentType: DocumentTypeEnum | null;
    confidience: number;
}

export interface GetAllDocumentsRequestDTO {
    searchTerm?: string;
    buildingGuid: string;
    pageNo: number;
    pageSize: number;
    documentCategories?: DocumentCategoryEnum[];
    unitIds: number[];
    sortOrder: SortOrder;
    sortField: DocumentSortFieldEnum;
}
