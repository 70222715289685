<h2 mat-dialog-title>
    <span class="flex flex-grow">{{ dialogData.dialogTitle }}</span>
    <button type="button" class="unstyled flex" (click)="onCancel()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

@if (dialogData.componentToLoad) {
    <mat-dialog-content>
        @if (isProcessing()) {
            <div class="loader-wrapper">
                <qpx-loader></qpx-loader>
            </div>
        }

        <ng-container
            *ngComponentOutlet="
                dialogData.componentToLoad;
                inputs: dialogData.componentToLoadData
            "
        >
        </ng-container>
    </mat-dialog-content>
}

<mat-dialog-actions>
    @if (!dialogData.hideCancelButton) {
        <div>
            <button
                type="button"
                mat-stroked-button
                color="primary"
                [disabled]="isProcessing()"
                (click)="onCancel()"
            >
                <mat-icon class="qpx-icon-sm"> close </mat-icon>
                <span>{{ dialogData.cancelButtonLabel || 'Cancel' }}</span>
            </button>
        </div>
    }

    <button
        type="button"
        mat-stroked-button
        [color]="
            dialogData.submitButtonTheme
                ? dialogData.submitButtonTheme
                : 'primary'
        "
        [disabled]="isProcessing() || !canConfirm()"
        (click)="onConfirm()"
    >
        @if (dialogData.submitButtonIcon) {
            <mat-icon class="qpx-icon-sm">
                {{ dialogData.submitButtonIcon }}
            </mat-icon>
        }
        <span>{{ dialogData.submitButtonLabel || 'Confirm' }}</span>
    </button>
</mat-dialog-actions>
