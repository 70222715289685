import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MonitoringService } from './monitoring.service';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HandleErrorService {
    private readonly snackBar = inject(MatSnackBar);
    private readonly logger = inject(MonitoringService);

    public handleError(err: HttpErrorResponse) {
        let errorMessage: string;

        if (err.error instanceof ErrorEvent) {
            // Client-side or network error
            errorMessage = `An error occurred: ${err.error.message}`;

            // Server-side error
        } else if (err.error?.title) {
            errorMessage = err.error.title;
        } else if (err.error?.message) {
            // Use error message from response if available
            errorMessage = err.error.message;
        } else {
            // Handle specific cases for server errors and fallbacks
            switch (true) {
                case err.status === 400:
                    errorMessage =
                        'Request failed. Please check your input and try again.';
                    break;
                case err.status === 403:
                    errorMessage =
                        'You do not have permission to complete this action.';
                    break;
                case err.status === 401:
                    errorMessage =
                        'You are not authorized to access this resource.';
                    break;
                case err.status === 404:
                    errorMessage = 'The requested resource was not found.';
                    break;
                case err.status === 500:
                    errorMessage =
                        'We encountered an unexpected problem. Please try again later.';
                    break;
                case err.status === 503:
                    errorMessage = 'The requested service is not available.';
                    break;
                default:
                    errorMessage = 'Something went wrong!';
            }
        }

        if (errorMessage) {
            this.snackBar.open(errorMessage, 'error', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: 'danger',
                duration: 1500,
            });
        }
        return throwError(() => errorMessage);
    }
}
