import { Inject, Injectable } from '@angular/core';
import {
    MSAL_GUARD_CONFIG,
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalService,
} from '@azure/msal-angular';
import {
    AccountInfo,
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionStatus,
    InteractionType,
    PopupRequest,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';

export type AccountType = AccountInfo | null;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private currentAccountSubject: BehaviorSubject<AccountType>;
    private isAuthenticating = false;

    currentAccount$: Observable<AccountType>;
    destroying$: Subject<void>;

    constructor(
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private readonly msalBroadcastService: MsalBroadcastService
    ) {
        this.currentAccountSubject = new BehaviorSubject<AccountType>(null);
        this.currentAccount$ = this.currentAccountSubject.asObservable();
        this.destroying$ = new Subject<void>();
        this.initialize();
    }

    private initialize() {
        this.setCurrentAccount();
        this.authService.instance.enableAccountStorageEvents();

        // Handle account changes
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.ACCOUNT_ADDED ||
                        msg.eventType === EventType.ACCOUNT_REMOVED
                )
            )
            .subscribe(() => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = '/';
                }
            });

        // Handle interaction completion
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this.destroying$)
            )
            .subscribe(() => {
                this.checkAndSetActiveAccount();
            });

        // Handle successful authentication
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this.destroying$)
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
            });
    }

    private setCurrentAccount() {
        const currentAccount = this.authService.instance.getActiveAccount();
        this.currentAccountSubject.next(currentAccount);
    }

    acquireTokenSilent(request: SilentRequest) {
        return this.authService.acquireTokenSilent(request);
    }

    getAccount() {
        return this.authService.instance.getActiveAccount();
    }

    private checkAndSetActiveAccount() {
        const activeAccount = this.authService.instance.getActiveAccount();

        if (
            !activeAccount &&
            this.authService.instance.getAllAccounts().length > 0
        ) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.authService.instance.getAllAccounts().length > 0) {
            return; // User is already logged in
        }

        if (this.isAuthenticating) {
            return; // Prevent multiple authentication attempts
        }

        this.isAuthenticating = true;

        try {
            if (
                this.msalGuardConfig.interactionType === InteractionType.Popup
            ) {
                if (this.msalGuardConfig.authRequest) {
                    this.authService
                        .loginPopup({
                            ...this.msalGuardConfig.authRequest,
                            ...userFlowRequest,
                        } as PopupRequest)
                        .subscribe({
                            next: (response: AuthenticationResult) => {
                                this.authService.instance.setActiveAccount(
                                    response.account
                                );
                                this.isAuthenticating = false;
                            },
                            error: () => {
                                this.isAuthenticating = false;
                            },
                        });
                } else {
                    this.authService.loginPopup(userFlowRequest).subscribe({
                        next: (response: AuthenticationResult) => {
                            this.authService.instance.setActiveAccount(
                                response.account
                            );
                            this.isAuthenticating = false;
                        },
                        error: () => {
                            this.isAuthenticating = false;
                        },
                    });
                }
            } else {
                if (this.msalGuardConfig.authRequest) {
                    this.authService.loginRedirect({
                        ...this.msalGuardConfig.authRequest,
                        ...userFlowRequest,
                    } as RedirectRequest);
                } else {
                    this.authService.loginRedirect(userFlowRequest);
                }
            }
        } catch (error) {
            this.isAuthenticating = false;
            throw error;
        }
    }

    logout() {
        this.authService.logout();
    }
}
