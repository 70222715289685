import { Inject, inject, Injectable } from '@angular/core';
import { ApiServiceBase } from '../api-service-base';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG_TOKEN, AppConfigModel } from '../models';
import {
    PublicClientApplication,
    Configuration,
    InteractionRequiredAuthError,
    BrowserCacheLocation,
} from '@azure/msal-browser';

export interface SharePointFile {
    id: string;
    name: string;
    size: number;
    lastModifiedDateTime: string;
    webUrl: string;
    '@microsoft.graph.downloadUrl': string;
    folder?: {
        childCount: number;
    };
}

export interface SharePointSite {
    id: string;
    name: string;
    displayName: string;
    webUrl: string;
    description?: string;
}

export enum FileSource {
    OneDrive = 'onedrive',
    SharePoint = 'sharepoint',
}

@Injectable({
    providedIn: 'root',
})
export class GraphApiService extends ApiServiceBase {
    private graphClient!: Client;
    private readonly authService = inject(AuthService);
    private graphMsalInstance!: PublicClientApplication;
    private initialized = false;

    constructor(
        @Inject(APP_CONFIG_TOKEN) appConfig: AppConfigModel,
        http: HttpClient
    ) {
        super(appConfig, http);
        this.initializeGraphAuth().catch((error) => {
            console.error('Error initializing Graph auth:', error);
        });
    }

    private async initializeGraphAuth() {
        const graphMsalConfig: Configuration = {
            auth: {
                clientId: environment.graphApiClientId,
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: window.location.origin,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true,
            },
            system: {
                allowRedirectInIframe: true,
                iframeHashTimeout: 10000,
                windowHashTimeout: 10000,
                tokenRenewalOffsetSeconds: 300, // 5 minutes before expiry
            },
        };

        this.graphMsalInstance = new PublicClientApplication(graphMsalConfig);
        await this.graphMsalInstance.initialize();

        await this.graphMsalInstance.handleRedirectPromise().catch((error) => {
            console.error('Error handling redirect:', error);
        });

        this.graphClient = this.initializeGraphClient();
        this.initialized = true;
    }

    private async getGraphToken(): Promise<string> {
        await this.ensureInitialized();

        try {
            const currentAccount = this.authService.getAccount();

            if (!currentAccount) {
                throw new Error('No active account! Please sign in first.');
            }

            try {
                const silentRequest = {
                    scopes: environment.scopes.graphApi as string[],
                    account: currentAccount,
                    forceRefresh: false,
                };

                const response =
                    await this.graphMsalInstance.acquireTokenSilent(
                        silentRequest
                    );
                return response.accessToken;
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    const interactiveRequest = {
                        scopes: environment.scopes.graphApi as string[],
                        account: currentAccount,
                    };

                    const response =
                        await this.graphMsalInstance.acquireTokenPopup(
                            interactiveRequest
                        );
                    return response.accessToken;
                }
                throw error;
            }
        } catch (error) {
            console.error('Error getting graph token:', error);
            throw error;
        }
    }

    private async ensureInitialized() {
        if (!this.initialized) {
            await this.initializeGraphAuth();
        }
    }

    private initializeGraphClient(): Client {
        return Client.init({
            authProvider: async (done) => {
                try {
                    const token = await this.getGraphToken();
                    done(null, token);
                } catch (error) {
                    done(error, null);
                }
            },
        });
    }

    /**
     * Get list of SharePoint sites the user has access to
     */
    public async getSharePointSites(): Promise<SharePointSite[]> {
        await this.ensureInitialized();

        try {
            const response = await this.graphClient
                .api('/sites?search=*')
                .get();
            return response.value;
        } catch (error) {
            console.error('Error getting SharePoint sites:', error);
            throw error;
        }
    }

    /**
     * Get root items based on the selected source (OneDrive or SharePoint site)
     */
    public async getRootItems(
        source: FileSource = FileSource.OneDrive,
        siteId?: string
    ): Promise<SharePointFile[]> {
        await this.ensureInitialized();

        try {
            let endpoint = '';
            if (source === FileSource.OneDrive) {
                endpoint = '/me/drive/root/children';
            } else if (source === FileSource.SharePoint && siteId) {
                endpoint = `/sites/${siteId}/drive/root/children`;
            } else {
                throw new Error(
                    'SharePoint site ID is required when source is SharePoint'
                );
            }

            const response = await this.graphClient.api(endpoint).get();
            return response.value;
        } catch (error) {
            console.error('Error getting root items:', error);
            throw error;
        }
    }

    /**
     * Get items from a specific folder
     */
    public async getFolderItems(
        folderId: string,
        source: FileSource = FileSource.OneDrive,
        siteId?: string
    ): Promise<SharePointFile[]> {
        await this.ensureInitialized();

        try {
            let endpoint = '';
            if (source === FileSource.OneDrive) {
                endpoint = `/me/drive/items/${folderId}/children`;
            } else if (source === FileSource.SharePoint && siteId) {
                endpoint = `/sites/${siteId}/drive/items/${folderId}/children`;
            } else {
                throw new Error(
                    'SharePoint site ID is required when source is SharePoint'
                );
            }

            const response = await this.graphClient.api(endpoint).get();
            return response.value;
        } catch (error) {
            console.error('Error getting folder items:', error);
            throw error;
        }
    }

    /**
     * Download a file
     */
    public async downloadFile(
        fileId: string,
        source: FileSource = FileSource.OneDrive,
        siteId?: string
    ): Promise<Blob> {
        await this.ensureInitialized();

        try {
            let endpoint = '';
            if (source === FileSource.OneDrive) {
                endpoint = `/me/drive/items/${fileId}/content`;
            } else if (source === FileSource.SharePoint && siteId) {
                endpoint = `/sites/${siteId}/drive/items/${fileId}/content`;
            } else {
                throw new Error(
                    'SharePoint site ID is required when source is SharePoint'
                );
            }

            const response = await this.graphClient.api(endpoint).get();
            return response;
        } catch (error) {
            console.error('Error downloading file:', error);
            throw error;
        }
    }
}

