import { CommonModule } from '@angular/common';
import {
    Component,
    DestroyRef,
    OnInit,
    computed,
    inject,
    input,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService, User, UserStore } from '@quipex/shared/data';
import { BulkUploadFilesComponent } from '../bulk-upload-files/bulk-upload-files.component';
import { LoaderComponent } from '../../loader.component';

@Component({
    selector: 'qpx-bulk-upload-files-modal',
    templateUrl: './bulk-upload-files-modal.component.html',
    imports: [
        CommonModule,
        MatDialogModule,
        BulkUploadFilesComponent,
        LoaderComponent,
    ],
})
export class BulkUploadFilesModalComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly dialogService = inject(DialogService);
    private readonly userStore = inject(UserStore);

    public readonly buildingId = input.required<string>();

    private readonly me$ = this.userStore.select('me');
    private readonly me = toSignal(this.me$);
    protected readonly isOwner = computed(() =>
        isRole(this.me(), 'OWNER', 'startWith')
    );
    private readonly isBuilder = computed(() => isRole(this.me(), 'BUILDER'));

    private readonly bulkUploadFiles = viewChild(BulkUploadFilesComponent);

    protected readonly showBuildingReviewScreen = computed(
        () => this.isBuilder() || this.isOwner()
    );

    ngOnInit(): void {
        this.handleDialogEvents();
    }

    private handleDialogEvents(): void {
        // on dialog Cancel
        this.dialogService.requestCancel$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.dialogService.closeDialog());

        // on dialog Save - Handle submit
        this.dialogService.requestConfirm$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.bulkUploadFiles()?.handleUploadDocuments();
            });
    }

    protected onIsProcessingChange(isProcessing: boolean): void {
        this.dialogService.setIsProcessing(isProcessing);
    }

    protected onCanConfirmChange(canConfirm: boolean): void {
        this.dialogService.setCanConfirm(canConfirm);
    }

    protected onUploadFinished(): void {
        this.dialogService.closeDialog(true);
    }
}

function isRole(
    user: User | undefined,
    _role: string,
    match: 'startWith' | 'includes' | 'exact' = 'includes'
): boolean {
    const userRole = user?.role?.toLowerCase();
    const checkRole = _role.toLowerCase();

    if (!userRole) return false;

    switch (match) {
        case 'startWith': {
            return userRole.startsWith(checkRole);
        }
        case 'includes': {
            return userRole.includes(checkRole);
        }
        case 'exact': {
            return userRole === checkRole;
        }
    }
}
