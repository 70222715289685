import { BuildingManualCreationStatus, BuildingStatus } from './enums.model';
import { IGetBuildingDetails } from './buildings';

export class AddNewBuilding {
    buildingDTO: BuildingDTO;

    constructor() {
        this.buildingDTO = new BuildingDTO();
    }

    updatedBuildingDTO(building: IGetBuildingDetails) {
        const {
            developerDirectors,
            postcode,
            occupancyCertificateDate,
            ...restBuilding
        } = building;

        Object.assign(this.buildingDTO, restBuilding);

        // FIXME: addressId prop is never returned
        // FIXME: keyDocuments prop always returns null and there is a mismatch between api call type and dto

        if (occupancyCertificateDate) {
            this.buildingDTO.occupancyCertificateDate = new Date(
                occupancyCertificateDate
            );
        }

        if (postcode) {
            this.buildingDTO.postcode = postcode.toString();
        }

        if (developerDirectors?.length) {
            developerDirectors.forEach((d) => {
                const director = new Director();
                if (d.name) {
                    director.name = d.name;
                    this.buildingDTO.developerDirectors.push(director);
                }
            });
        }
    }
}

export interface IAddNewBuildingPostModel {
    buildingId?: string;
    buildingName: string;
    about: string;
    title: string;
    postcode: string;
    cityName: string;
    streetName: string;
    streetNumber: string;
    stateName: string;
    countryName: string;
    address: string;
    builder?: string;
    propertyOwner?: string;
    latitude?: number;
    longitude?: number;
    siteArea?: number;
    floorArea?: number;
    riseInStoreys?: number;
    effectiveHeight?: number;
    maxOccupants: string;
    noOfSoleOccupancyUnits: string;
    developerName: string;
    developerDirectors: IDirector[];
    addressId?: string;
    occupancyCertificateDate?: Date;
    creationStatus: BuildingManualCreationStatus;
}

export class BuildingDTO implements IAddNewBuildingPostModel {
    buildingId?: string;
    buildingName = '';
    about = '';
    title = '';
    postcode = '';
    cityName = '';
    streetName = '';
    streetNumber = '';
    stateName = '';
    state!: number;
    countryName = '';
    address!: string;
    propertyOwner!: any;
    builder!: any;
    buildingStatusId!: BuildingStatus;
    classId: Array<string> = [''];
    latitude = 0;
    longitude = 0;

    siteArea!: number;
    floorArea!: number;
    riseInStoreys!: number;
    effectiveHeight!: number;
    maxOccupants!: string;
    noOfSoleOccupancyUnits!: string;
    classes = '';

    developerName = '';
    developerDirectors: Director[] = [];
    keyDocuments: KeyDocument[] = [];
    developerDirectorNames = '';
    occupancyCertificateDate?: Date;
    addressId?: string;
    creationStatus: BuildingManualCreationStatus =
        BuildingManualCreationStatus.Draft;
}

export interface IOCDetailsExtraction {
    title?: string;
    buildingClasses?: Array<string>;
    riseInStoreys?: number;
    effectiveHeight?: number;
    ocIssueDate?: string;
    about?: Array<string>;
    address?: string;
    maxOccupants?: string;
    buildingSurveyor?: string;
    buildingSurveyorRegistration?: string;
    isAddressMatch?: boolean;
}

export interface IDirector {
    name?: string;
}

export class Director {
    name = '';
}

export class KeyDocument {
    documentId!: string;
    documentName!: string;
    keyDocumentType!: number;
}

export class ClassModel {
    classGuid!: string;
    constructionType!: string;
    description!: string;
    id!: number;
    name!: string;
    checked!: boolean;
}
