<qpx-bulk-upload-files
    viewType="upload-with-file-list"
    [showBuildingReviewScreen]="showBuildingReviewScreen()"
    [canClassifyDocuments]="false"
    [buildingId]="buildingId()"
    (uploadFinished)="onUploadFinished()"
    (isProcessingChange)="onIsProcessingChange($event)"
    (canConfirmChange)="onCanConfirmChange($event)"
    [loadingTpl]="loadingTpl"
/>

<ng-template #loadingTpl>
    <qpx-loader containerClass="flex justify-center align-center" />
</ng-template>
