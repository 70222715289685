import { InjectionToken } from '@angular/core';

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfigModel>(
    'app.config'
);

export interface AppConfigModel {
    appVersion: string;
    isProd: boolean;
    apiBase: string;
    apiFunctionBase: string;
    apiAIBase: string;
    addressApiBase: string;
    scopes: {
        [key: string]: string[];
    };
    clientId: string;
    tenantId: string;
    graphApiClientId: string;
    sessionConfig: {
        inactiveTimeoutSeconds: number;
        idleTimeoutSeconds: number;
    };
}
