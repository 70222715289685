<div class="building-search">
    <button
        type="button"
        class="unstyled search-navbar"
        #triggerRef
        (click)="toggleDropdown()"
    >
        <mat-icon>search</mat-icon>
        <span class="search-navbar__text">Find a building</span>
    </button>

    @if (isDropdownOpen()) {
        <div
            #dropdownRef
            class="building-dropdown"
            [class.expanded]="!!filteredBuildingNames().length"
        >
            <div class="header">
                <span>Find a building</span>
                <button
                    type="button"
                    class="unstyled flex close-icon"
                    (click)="toggleDropdown()"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div class="search-input-container">
                <form [formGroup]="form()">
                    <mat-form-field class="w-100 no-error focused-primary">
                        <mat-icon matPrefix>search</mat-icon>
                        <input
                            #searchInputRef
                            type="text"
                            name="buildingName"
                            data-testid="buildingNameLink"
                            spellcheck="false"
                            autocomplete="off"
                            placeholder="Search building address"
                            matInput
                            maxlength="50"
                            [matAutocomplete]="search"
                            [formControl]="controlSearch()"
                        />
                        <mat-icon
                            matSuffix
                            class="cursor-pointer"
                            (click)="clearSearch($event)"
                            >close</mat-icon
                        >
                        @if (isLoading()) {
                            <qpx-loader />
                        }

                        <mat-autocomplete
                            autoActiveFirstOption
                            #search="matAutocomplete"
                            class="building-search-autocomplete"
                            (optionSelected)="onBuildingNameSelected($event)"
                        >
                            @for (
                                result of filteredBuildingNames();
                                track result.buildingGuid
                            ) {
                                <mat-option
                                    class="result-mat-option"
                                    [value]="result"
                                    [disabled]="result.buildingGuid === '0'"
                                >
                                    @if (result.buildingGuid !== '0') {
                                        <div class="title">
                                            {{ result | qpxAddress: 'street' }}
                                        </div>
                                        <div class="subtitle">
                                            {{ result | qpxAddress: 'city' }}
                                        </div>
                                    } @else {
                                        <div class="no-results">
                                            No Match Found
                                        </div>
                                    }
                                </mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
        </div>
    }
</div>
