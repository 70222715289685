import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    ElementRef,
    inject,
    OnInit,
    Signal,
    signal,
    viewChild,
} from '@angular/core';
import {
    NonNullableFormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import {
    BuildingApiService,
    SearchService,
    UserStore,
    IBuildingResponse,
    GetBuildingsRequestDTO,
    SortField,
    SortOrder,
    IBuildingListResponse,
} from '@quipex/shared/data';
import { of } from 'rxjs';
import {
    catchError,
    combineLatestWith,
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    switchMap,
} from 'rxjs/operators';
import { LoaderComponent } from '../loader.component';
import { AddressPipe } from '@quipex/shared/pipes';
import {
    takeUntilDestroyed,
    toObservable,
    toSignal,
} from '@angular/core/rxjs-interop';
import {
    DEFAULT_MINIMUM_SEARCH_CHARACTERS,
    validateSearchInput,
} from '@quipex/shared/helpers';

type BuildingSearchForm = FormGroup<{
    buildingNameSearch: FormControl<string>;
}>;

const emptyResponse: IBuildingListResponse = {
    count: 0,
    filterdCount: 0,
    buildings: [],
};

@Component({
    selector: 'qpx-building-search',
    templateUrl: './building-search.component.html',
    styleUrls: ['./building-search.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatIconModule,
        MatListModule,
        MatInputModule,
        MatAutocompleteModule,
        LoaderComponent,
        AddressPipe,
    ],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'building-search-autocomplete' },
        },
    ],
    host: {
        '(document:click)': 'onClickAway($event)',
        '(keydown.escape)': 'onEscapePress()',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingSearchComponent implements OnInit {
    private readonly buildingService = inject(BuildingApiService);
    private readonly searchService = inject(SearchService);
    private readonly fb = inject(NonNullableFormBuilder);
    private readonly router = inject(Router);
    private readonly elRef = inject(ElementRef, { host: true });
    private readonly userStore = inject(UserStore);
    private readonly destroyRef = inject(DestroyRef);

    protected readonly isDropdownOpen = signal(false);
    private readonly isDropdownOpen$ = toObservable(this.isDropdownOpen);
    protected readonly isLoading = signal(false);
    protected readonly filteredBuildingNames = signal<IBuildingResponse[]>([]);

    protected readonly triggerRef = viewChild<
        unknown,
        ElementRef<HTMLButtonElement>
    >('triggerRef', { read: ElementRef });
    protected readonly dropdownRef = viewChild<
        unknown,
        ElementRef<HTMLElement>
    >('dropdownRef', { read: ElementRef });
    protected readonly searchInputRef = viewChild<
        unknown,
        ElementRef<HTMLInputElement>
    >('searchInputRef', { read: ElementRef });
    private readonly searchInputEl$ = toObservable(this.searchInputRef);

    private readonly _form: BuildingSearchForm;
    protected readonly form: Signal<BuildingSearchForm>;

    protected readonly controlSearch = computed(
        () => this.form().controls.buildingNameSearch
    );

    private readonly me$ = this.userStore.select('me');
    private readonly me = toSignal(this.me$, { initialValue: null });

    private readonly _currentUserRole = computed(() => this.me()?.role ?? '');

    constructor() {
        this._form = this.configureForm();
        this.form = toSignal(
            this._form.valueChanges.pipe(
                combineLatestWith(this._form.statusChanges),
                map(() => this._form)
            ),
            { initialValue: this._form }
        );
    }

    ngOnInit() {
        this.configureBuildingNameSearch();
        this.focusInputOnDropdownOpen();
        this.resetSearchOnDropdownClose();
    }

    private resetSearchOnDropdownClose() {
        /** excludes initial value */
        const onDropdownCloseEvents$ = this.isDropdownOpen$.pipe(
            distinctUntilChanged(),
            pairwise(),
            filter(([prev, next]) => prev != null && !next)
        );

        onDropdownCloseEvents$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.resetSearchCriteria();
                // restore focus to triggering button
                this.triggerRef()?.nativeElement?.focus();
            });
    }

    /** Close the dropdown when clicking outside */
    protected onClickAway(event: Event) {
        if (!this.isDropdownOpen() || !this.dropdownRef()) return;

        const eventTarget = event.target;

        if (!(eventTarget instanceof HTMLElement)) return;

        // the autocomplete dropdown is appended to document body DOM,
        // not as child of this component
        const isMatAutocomplete = eventTarget.id?.includes('mat-autocomplete');
        if (isMatAutocomplete) return;

        const isClickedInsideComponent =
            this.elRef.nativeElement.contains(eventTarget);
        if (!isClickedInsideComponent) {
            this.isDropdownOpen.set(false);
        }
    }

    protected onEscapePress(): void {
        this.isDropdownOpen.set(false);
    }

    protected toggleDropdown() {
        this.isDropdownOpen.update((state) => !state);
    }

    private focusInputOnDropdownOpen() {
        this.searchInputEl$
            .pipe(
                filter((el) => !!el),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((el) => el.nativeElement.focus());
    }

    protected clearSearch($event: Event): void {
        $event.stopPropagation(); // Prevents the click event from reaching the parent (closing dropdown)
        this.resetSearchCriteria();
        this.searchInputRef()?.nativeElement?.focus();
    }

    protected onBuildingNameSelected(
        event: MatAutocompleteSelectedEvent
    ): void {
        const value = event.option.value as IBuildingResponse;
        this.navigateToDetails(value.buildingGuid);
        this.controlSearch().setValue('');
        this.searchService.closeSearch();
    }

    private navigateToDetails(id: string): void {
        const userRole = this._currentUserRole().toLowerCase();

        let route = '';
        if (userRole.includes('builder')) {
            route = 'site-details';
        } else if (userRole.includes('maintenance')) {
            route = 'maintenance';
        }

        const navigationPath = ['/buildings', id, route].filter(Boolean);

        this.router.navigate(navigationPath);
        this.isDropdownOpen.set(false);
    }

    private configureForm(): BuildingSearchForm {
        const form = this.fb.group({
            buildingNameSearch: this.fb.control(''),
        });

        return form;
    }

    private configureBuildingNameSearch(): void {
        this.controlSearch()
            .valueChanges.pipe(
                validateSearchInput(),
                switchMap((value) => {
                    // clear if there are 0 characters
                    if (value.length === 0) {
                        return of(emptyResponse);
                    }

                    // do nothing if there are less than 3 characters
                    if (value.length < DEFAULT_MINIMUM_SEARCH_CHARACTERS) {
                        return of(null);
                    }

                    // otherwise search
                    const reqBody: GetBuildingsRequestDTO = {
                        pageNo: 1,
                        pageSize: 10,
                        searchTerm: value,
                        sortField: SortField.Building,
                        sortOrder: SortOrder.Ascending,
                    };

                    this.isLoading.set(true);

                    return this.buildingService.getBuildings(reqBody).pipe(
                        map((response) => response),
                        catchError(() => {
                            this.isLoading.set(false);
                            return of(emptyResponse);
                        })
                    );
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((response) => {
                if (response) {
                    this.filteredBuildingNames.set(response.buildings);
                }

                this.isLoading.set(false);
            });
    }

    private resetSearchCriteria(): void {
        this.controlSearch().setValue('');
        this.filteredBuildingNames.set([]);
    }
}
